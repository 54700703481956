export const MenuStyles = {
  mapIconTitle: {
    fontWeight: 600,
    color: 'black',
    paddingTop: '4px',
  },
  card: {
    textAlign: 'center',
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: '12px',
  },
  cardNumber: {
    fontSize: '23px',
    fontWeight: 500,
  },
  gridItemRealizarCheckIn: {
    paddingTop: '15px',
  },
  gridTop: {
    padding: 10,
  },
  grid: {
    padding: '0px 10px 0px 10px',
  },
  menuTitle: {
    fontWeight: 'bold',
  },
  menuSubTitle: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#616161',
    verticalAlign: 'text-top',
    paddingLeft: '3px',
  },
  gridItemMenuSubTitle: {
    paddingTop: 0,
  },
};

export const CardStyles = {
  border: '1px solid rgb(217, 217, 217)',
  boxShadow: 'none',
  margin: 0,
};

export const NewAppBannerStyles = {
  position: 'fixed',
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  background: 'rgba(0,0,0, .5)',
  justifyContent: 'center',
  zIndex: 2,
};

export const NewAppBannerImgStyles = {
  maxWidth: '95%',
};

export const NewAppBannerDivStyles = {
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
};

export const CloseButtonStyles = {
  width: '40px',
  height: '40px',
  position: 'absolute',
  right: '10px',
  border: 'none',
  background: 'none',
};

export const StoreButtonStyles = {
  bottom: '25px',
  position: 'absolute',
  border: 'none',
  background: 'none',
  width: 'auto',
};

export const ImageButtonStyles = {
  maxWidth: 450,
};
